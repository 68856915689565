import React from 'react';
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    borderTable: {
        borderLeft:'1px solid black',
        borderRight:'1px solid black',
        borderBottom:'1px solid black'
    },
    borderTopBottom: {
        borderTop:'1px solid black',
        borderBottom:'1px solid black'
    },
    borderNone: {
        border: 'none'
    }
}))

export default function NutriFacts(props) {
    const classes = useStyles()

    const expdata = [
        {
            "componente": "Energia",
            "unidade": "kJ",
            "valor/g": 2.46
        },
        {
            "componente": "Energia",
            "unidade": "kcal",
            "valor/g": 0.58
        },
        {
            "componente": "Umidade",
            "unidade": "g",
            "valor/g": 0.88
        },
        {
            "componente": "Carboidrato total",
            "unidade": "g",
            "valor/g": 0.06709999999999999
        },
        {
            "componente": "Carboidrato",
            "unidade": "g",
            "valor/g": 0.06709999999999999
        },
        {
            "componente": "Proteína",
            "unidade": "g",
            "valor/g": 0.0203
        },
        {
            "componente": "Lipídios",
            "unidade": "g",
            "valor/g": 0.0264
        },
        {
            "componente": "Fibra alimentar",
            "unidade": "g",
            "valor/g": 0.0
        },
        {
            "componente": "Álcool",
            "unidade": "g",
            "valor/g": 0.0
        },
        {
            "componente": "Cinzas",
            "unidade": "g",
            "valor/g": 0.0060999999999999995
        },
        {
            "componente": "Colesterol",
            "unidade": "mg",
            "valor/g": 0
        },
        {
            "componente": "Ácidos graxos saturados",
            "unidade": "g",
            "valor/g": 0.011200000000000002
        },
        {
            "componente": "Ácidos graxos monoinsaturados",
            "unidade": "g",
            "valor/g": 0.011200000000000002
        },
        {
            "componente": "Ácidos graxos poliinsaturados",
            "unidade": "g",
            "valor/g": 0.0031
        },
        {
            "componente": "Ácidos graxos trans",
            "unidade": "g",
            "valor/g": 0
        },
        {
            "componente": "Cálcio",
            "unidade": "mg",
            "valor/g": 0.228
        },
        {
            "componente": "Ferro",
            "unidade": "mg",
            "valor/g": 0
        },
        {
            "componente": "Sódio",
            "unidade": "mg",
            "valor/g": 0.434
        },
        {
            "componente": "Magnésio",
            "unidade": "mg",
            "valor/g": 0.0264
        },
        {
            "componente": "Fósforo",
            "unidade": "mg",
            "valor/g": 0.142
        },
        {
            "componente": "Potássio",
            "unidade": "mg",
            "valor/g": 0.43700000000000006
        },
        {
            "componente": "Zinco",
            "unidade": "mg",
            "valor/g": 0.0060999999999999995
        },
        {
            "componente": "Cobre",
            "unidade": "mg",
            "valor/g": 0.0005
        },
        {
            "componente": "Selênio",
            "unidade": "mcg",
            "valor/g": 0
        },
        {
            "componente": "Vitamina A (RE)",
            "unidade": "mcg",
            "valor/g": 1.68
        },
        {
            "componente": "Vitamina A",
            "unidade": "mcg",
            "valor/g": 0
        },
        {
            "componente": "Vitamina D",
            "unidade": "mcg",
            "valor/g": 0.0
        },
        {
            "componente": "Vitamina E",
            "unidade": "mg",
            "valor/g": 0.0132
        },
        {
            "componente": "Vitamina B1",
            "unidade": "mg",
            "valor/g": 0.0
        },
        {
            "componente": "Vitamina B2",
            "unidade": "mg",
            "valor/g": 0.0003
        },
        {
            "componente": "Vitamina B3",
            "unidade": "mg",
            "valor/g": 0.008100000000000001
        },
        {
            "componente": "Vitamina B6",
            "unidade": "mg",
            "valor/g": 0.0
        },
        {
            "componente": "Vitamina B12",
            "unidade": "mcg",
            "valor/g": 0.001
        },
        {
            "componente": "Vitamina C",
            "unidade": "mg",
            "valor/g": 0.0712
        },
        {
            "componente": "Vitamina B9",
            "unidade": "mcg",
            "valor/g": 0.0203
        },
        {
            "componente": "Sal de adição",
            "unidade": "g",
            "valor/g": 0.0
        },
        {
            "componente": "Açúcar de adição",
            "unidade": "g",
            "valor/g": 0.0
        }
    ];
    const macroIDs = [4, 5, 6, 7]
    const saisIDs = [15, 16, 17, 18, 19, 20, 21, 22, 23];
    const vitaIDs = [25, 26, 27, 28, 29, 30, 31, 32, 33, 34];
    const mIDs = [[4, 25, 15], [5, 26, 16], [6, 27, 17], [7, 28, 18]]
    const svIDs = [[29, 19],[30, 20],[31, 21],[32, 22],[33, 23]]

    //const vitaIDs = [[24, 25], 26, 27, 28, 29, 30, 31, 32, 33, 34];
    //const macroIDs = [1, 2, 3, 4];
    //const saisIDs = [5, 6, 7, 8, 9, 10, 11, 12, 13];
    //const vitaIDs = [14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

    const adPrint = (
        <>
            {
                props.ad ?
                <TableRow style={{borderBottom:'1px solid black'}}>
                    <TableCell colspan="6" align="center" style={{borderBottom:'1px solid black'}}>
                        <img src={props.ad} class="mx-auto" />
                    </TableCell>
                </TableRow>
                :
                ''
            }
        </>
    )

    return(
        <TableContainer>
        <Table size="small">
          <TableHead>

            <TableRow style={{borderTop:'3px solid black',borderBottom:'3px solid black'}}>
              <TableCell colspan="2" align="center">
                <Typography variant="h6" style={{fontWeight:'bold'}}>TABELA NUTRICIONAL</Typography>
              </TableCell>
            </TableRow>
            <TableRow style={{borderTop:'3px solid black',borderBottom:'3px solid black'}}>
              <TableCell align="center" colspan="1">
                <Typography variant="h10" style={{fontWeight:'bold'}}>CALORIAS TOTAIS<br/>por grama</Typography>
              </TableCell >
              <TableCell colspan="1" id="nutri_1_value"></TableCell>
            </TableRow>

          </TableHead>
          <TableBody>

            {adPrint}

            <TableRow className={classes.borderTopBottom}>
                <TableCell colspan="2" align="center" style={{border:'none',fontWeight:'bold',fontStyle:'italic'}}>
                    Macronutrientes
                </TableCell>
            </TableRow>
            {
                macroIDs.map(id => (
                    <TableRow style={{borderBottom:'1px dashed black'}}>
                        
                        <TableCell align="left" style={{border:'none'}}>
                            {expdata[id].componente} - {expdata[id].unidade}
                        </TableCell>

                        <TableCell id={'nutri_'+id+'_value'} align="right" style={{border:'none'}}>
                            0
                        </TableCell>

                    </TableRow>
                ))
            }

            <TableRow className={classes.borderTopBottom}>
                <TableCell colspan="2" align="center" style={{border:'none',fontWeight:'bold',fontStyle:'italic'}}>
                    Vitaminas
                </TableCell>
            </TableRow>
            {
                vitaIDs.map(id => (
                    <TableRow style={{borderBottom:'1px dashed black'}}>
                        
                        <TableCell align="left" style={{border:'none'}}>
                            {expdata[id]["componente"]} - {expdata[id].unidade}
                        </TableCell>

                        <TableCell id={'nutri_'+id+'_value'} align="right" style={{border:'none'}}>
                            0
                        </TableCell>

                    </TableRow>
                ))
            }


            <TableRow className={classes.borderTopBottom}>
                <TableCell colspan="2" align="center" style={{border:'none',fontWeight:'bold',fontStyle:'italic'}}>
                    Sais minerais
                </TableCell>
            </TableRow>
            {
                saisIDs.map(id => (
                    <TableRow style={{borderBottom:'1px dashed black'}}>
                        <TableCell align="left" style={{border:'none'}}>
                            {expdata[id]["componente"]} - {expdata[id].unidade}
                        </TableCell>
                        <TableCell id={'nutri_'+id+'_value'} align="right" style={{border:'none'}}>
                            0
                        </TableCell>
                    </TableRow>
                ))
            }

          </TableBody>
        </Table>
      </TableContainer>
    );
}