import React, { useState } from "react"
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import NutriFacts from './form/nutrifacts'
import XSNutriFacts from './form/xsnutrifacts'
import NutriForm from './form/nutriform'
import Hidden from '@material-ui/core/Hidden'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
 
const Body = () => {

    const theme = useTheme()
    
    return (
        <Grid>

            <Grid container direction="row" justify="center" alignItems="center" spacing={0} className={"pt-6"}>
                
                <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>

                    <Box className={"w-full flex flex-col lg:flex-row jusify-center items-start content-start"}>
            
                        <Box className={"w-full lg:w-5/12 mr-0 lg:mr-3 order-1"}>

                            <Paper elevation={3} className={"mx-1 xl:mx-0 mb-3 border-t-4 border-green-700"}>
                                <Paper elevation={0} className={'p-3 m-2'}>

                                    <h1 className={"p-3 mx-auto text-3xl tracking-widest font-extrabold text-center text-green-700"}>
                                        Cálculo Nutricional
                                    </h1>

                                    <p style={{textAlign:'center'}}>
                                        Monte sua refeição e obtenha os nutrientes.
                                        <br />
                                        Pesquise os alimentos desejados para obter os dados nutricionais.
                                    </p>
                                    
                                    <NutriForm
                                        //ad1={useMediaQuery(theme.breakpoints.down('sm')) ? 'https://via.placeholder.com/320x50?text=320x50' : 'https://via.placeholder.com/468x60?text=468x60'}
                                        
                                        //ad2={useMediaQuery(theme.breakpoints.up('md')) ? 'https://via.placeholder.com/468x60?text=468x60' : 'https://via.placeholder.com/320x50?text=320x50'}
                                    />

                                </Paper>
                            </Paper>

                        </Box>

                        <Box className={"w-full lg:w-7/12 order-2"}>
                            <Paper elevation={3} className={"mx-1 xl:mx-0 mb-3 border-t-4 border-green-700"}>
                                <Paper elevation={0} className={'p-3 m-2'}>
                                    
                                    <Hidden only={['xs', 'sm']}>
                                        <NutriFacts
                                            //ad="https://via.placeholder.com/468x60?text=468x60"
                                        />
                                    </Hidden>
                                    <Hidden only={['md', 'lg', 'xl']}>
                                        <XSNutriFacts
                                            //ad="https://via.placeholder.com/200x200?text=468x60"
                                        />
                                    </Hidden>

                                </Paper>
                            </Paper>
                        </Box>

                    </Box>

                </Grid>

            </Grid>

            <div 
            className={"bg-gray-800 h-80 -mb-60 overflow-hidden"}
            style={{clipPath:"polygon(100% 25%, 0 25%, 100% 0)"}}
            >
            </div>

        </Grid>
    )
}

export default Body;